<template>
  <div class="">
    <div class="col-12">
      <div class="card">
        <slot name="start">
          <Toolbar class="tw-mb-8 tw-p-3">
            <template #start>
              <div>
                <Button
                  label="Tambah"
                  icon="pi pi-plus"
                  class="p-button-sm p-button-success tw-bg-blue-light"
                  @click="navigateToCreate"
                />
              </div>
            </template>
          </Toolbar>
        </slot>
        <div class="text-xl font-medium text-black mb-3">
          <p>Kategori</p>
        </div>
        <DataTable
          :value="category"
          :paginator="true"
          :rows="10"
          dataKey="id"
          :rowHover="true"
          v-model:filters="filters1"
          filterDisplay="menu"
          :filters="filters1"
          responsiveLayout="scroll"
          :globalFilterFields="['name', 'no']"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          :rowsPerPageOptions="perPageOptions"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
        >
          <template #header>
            <div class="flex justify-content-between flex-column sm:flex-row">
              <Button
                type="button"
                icon="pi pi-filter-slash"
                label="Hapus Filter"
                class="p-button-outlined mb-2"
                @click="clearFilter()"
              />
              <span class="p-input-icon-left mb-2">
                <i class="pi pi-search" />
                <InputText
                  v-model="filters1['global'].value"
                  placeholder="Kata kunci"
                  style="width: 100%"
                />
              </span>
            </div>
          </template>
          <template #empty>
        <div class="tw-flex tw-justify-center tw-items-center tw-py-20 tw-text-2xl tw-font-bold">
          Tidak ada data yang ditemukan
        </div>
      </template>
      <template #loading>
        <div class="tw-flex tw-justify-center tw-items-center tw-py-20">
          Loading records, please wait...
        </div>
      </template>
          <Column field="no" header="No." style="min-width: 12rem">
            <template #body="{ data }">
              {{ data.no }}
            </template>
            <template #filter="{ filterModel }">
              <InputText
                type="text"
                v-model="filterModel.value"
                class="p-column-filter"
                placeholder="Search by number"
              />
            </template>
          </Column>
          <Column header="Nama Kategori" filterField="name" style="min-width: 12rem">
            <template #body="{ data }">
              {{ data.name }}
            </template>
            <template #filter="{ filterModel }">
              <InputText
                type="text"
                v-model="filterModel.value"
                class="p-column-filter"
                placeholder="Search by name"
              />
            </template>
          </Column>
          <Column
            header="Aksi"
            :showFilterMatchModes="false"
            :filterMenuStyle="{ width: '14rem' }"
            style="min-width: 14rem"
          >
            <template #body="{ data }">
              <Button
                icon="pi pi-eye"
                class="p-button-rounded p-button-info"
                @click="detailData(data.id)"
              />
              <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-warning ml-1"
                @click="editData(data.id)"
              />
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode, FilterOperator } from "primevue/api";
import CategoryService from "@/service/CategoryService";
export default {
  props: {
    perPageOptions: {
      type: Array,
      required: false,
      default() {
        return [10, 20, 50];
      },
    },
  },
  data() {
    return {
      category: [],
      filters1: null,
    };
  },
  categoryService: null,
  created() {
    this.categoryService = new CategoryService();
    this.initFilters();
  },
  mounted() {
    this.loadData();
  },
  methods: {
    navigateToCreate() {
      this.$router.push({ name: "create-category" });
    },
    editData(id) {
      this.$router.push({ name: "edit-category", params: { id: id } });
    },
    detailData(id) {
      this.$router.push({ name: "detail-category", params: { id: id } });
    },
    loadData() {
      this.categoryService.getAll().then((response) => {
        if (response.code === 200) {
          this.category = response.data.categories;
          const temp = [];
          this.category.forEach((category, idx) => {
            temp.push({ ...category, no: idx + 1 });
          });
          this.category = temp;
        }
      });
    },
    initFilters() {
      this.filters1 = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        no: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
        name: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
      };
    },
    clearFilter() {
      this.initFilters();
    },
  },
};
</script>

<style scoped lang="scss">
</style>
